import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  constructor(
    private featureFlagService: FeatureFlagService,
  ) {
  }

  isVoiceMessagesV1Enabled(): Observable<boolean> {
    return this.featureFlagService.isEnabled('voice-messages-1.0');
  }

  isDisabledFeaturesEnabled(): Observable<boolean> {
    return this.featureFlagService.isEnabled('disabled_features');
  }

  isBillingEnabled(): Observable<boolean> {
    return this.featureFlagService.isEnabled('billing');
  }
}
