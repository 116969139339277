import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, timeout } from 'rxjs';
import { catchError, defaultIfEmpty, map } from 'rxjs/operators';
import { EMPTY_OBSERVABLE } from '@app/util/rxjs.util';

const RESPONSE_TIMEOUT = 5000;

@Injectable({
  providedIn: 'root',
})
export class IpAddressService {
  private urlToGetIp = 'https://ipapi.co/json';

  constructor(private httpClient: HttpClient) {
  }

  getIPAddress(): Observable<string> {
    return this.httpClient.get(this.urlToGetIp)
      .pipe(
        map((response: any) => response.ip),
        timeout({ first: RESPONSE_TIMEOUT }),
        catchError(() => EMPTY_OBSERVABLE),
        defaultIfEmpty(() => null),
      );
  }
}
